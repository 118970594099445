import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "./Image";
import PortableText from "./PortableText";
import NewsLetter from "./NewsLetter";

export default function Footer({ menu }) {
  const { sanityFooter } = useStaticQuery(graphql`
    {
      sanityFooter {
        _rawBody
        text
        logo {
          asset {
            id
            originalFilename
            url
          }
          altText
        }
      }
    }
  `);

  return (
    <footer
      className="footer"
      style={{
        opacity: menu ? "0" : "1",
        pointerEvents: menu ? "none" : "all"
      }}
    >
      <div className="footer___overlay">
        <div className="footer___form">
          <NewsLetter />
        </div>
        <PortableText blocks={sanityFooter._rawBody} />
        <div className="footer___logo">
          <p className="font-p">{sanityFooter.text}</p>
          <Image image={sanityFooter.logo} />
        </div>
        <small>© Bad Fiji Gyals 2020</small>
      </div>
    </footer>
  );
}
