import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";

const sanityConfig = {
  projectId: "tj5lg93h",
  dataset: "production",
  apiVersion: "2021-03-25"
};

const Image = ({ image, maxWidth = 1600, objectFit, style, className }) => {
  if (!image?.asset) {
    return null;
  }

  let imageData;

  if (image?.asset?.id) {
    imageData = getGatsbyImageData(image.asset.id, { maxWidth }, sanityConfig);
  } else {
    imageData = getGatsbyImageData(
      image.asset._ref,
      { maxWidth },
      sanityConfig
    );
  }

  const {
    asset: { originalFilename, url },
    altText,
    alt
  } = image;

  return (
    <>
      {(url?.includes(`.svg`) && (
        <img src={url} alt={altText || originalFilename} />
      )) || (
        <GatsbyImage
          image={imageData}
          alt={altText || alt || originalFilename}
          imgStyle={{ objectFit: objectFit || `cover` }}
          style={style}
          className={className}
        />
      )}
    </>
  );
};

export default Image;
