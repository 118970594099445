import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const NewsLetter = () => {
  const [form, setForm] = useState({ email: '', result: null })

  const handleSubmit = async (event) => {
    event.preventDefault()

    const result = await addToMailchimp(form.email)
    setForm({ ...form, result: result })
  }

  const handleChange = (event) => {
    setForm({ ...form, email: event.target.value })
  }

  const clearForm = (time) => {
    setTimeout(() => {
      setForm({ email: '', result: null })
    }, time)
  }

  const formResult = () => {
    if (form.result) {
      if (form.result.result === 'success') {
        clearForm(3000)
        return <p className='font-p'>{form.result.msg}</p>
      } else if (form.result.result === 'error') {
        if (form.result.msg.includes('already subscribed')) {
          clearForm(3000)
          return <p className='font-p'>This email is already subscribed!</p>
        } else {
          clearForm(5000)
          return <p className='font-p'>{form.result.msg}</p>
        }
      }
    } else {
      return <p className='font-p'>Sign up to our news letter!</p>
    }
  }

  return (
    <>
      {formResult()}
      <form action='#' className='footer___form' onSubmit={handleSubmit}>
        <input
          type='email'
          placeholder='E-mail'
          name='email'
          onChange={handleChange}
          value={form.email}
        />
        <button type='submit' className='font-p'>
          Send
        </button>
      </form>
    </>
  )
}

export default NewsLetter
