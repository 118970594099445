import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "./Image";

import PortableText from "./PortableText";

export default function PopUp({ popUp, setpopUp }) {
  const { sanityPopUpPage } = useStaticQuery(graphql`
    {
      sanityPopUpPage {
        logo {
          asset {
            id
            originalFilename
            url
          }
          altText
        }
        _rawBody
        timeOut
      }
    }
  `);

  const setWithExpiry = (key, ttl) => {
    const now = new Date();

    const item = {
      expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getWithExpiry = key => {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return true;
    } else {
      return false;
    }
  };

  const minutesToMilliseconds = minutes => {
    const milliseconds = minutes * 60 * 1000;
    return milliseconds;
  };

  const timeOut = minutesToMilliseconds(sanityPopUpPage.timeOut);

  useEffect(() => {
    //on componentDidMount
    //checks if there is an item in local storage called popUp
    switch (getWithExpiry("popUp")) {
      case null:
        //locale storage is null. There is no item in local storage called popUp
        setpopUp(true);
        break;

      case false:
        //locale storage has not expired. Hide popUp
        setpopUp(false);
        break;

      case true:
        //locale storage has expired! Show PopUp
        setpopUp(true);
        break;
      default:
    }
  }, []);

  const clickHandler = () => {
    window.scrollTo(0, 0);
    setpopUp(false);

    //onClick adds new local storage item
    setWithExpiry("popUp", timeOut);
  };

  return (
    popUp && (
      <section className="pop-up">
        <div className="pop-up___overlay">
          <Image image={sanityPopUpPage.logo} className="pop-up___image" />
          <PortableText blocks={sanityPopUpPage._rawBody} />
          <button className="font-h1" onClick={clickHandler}>
            Enter Site
          </button>
        </div>
      </section>
    )
  );
}
