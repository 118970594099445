import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { hexToRgb, Color, Solver } from '../hex'

import SEO from './SEO'
import Nav from './Nav'
import Footer from './Footer'
import PopUp from './PopUp'

export default function Layout({ title, url, page, children }) {
  const data = useStaticQuery(graphql`
    query Layout {
      colourPallets: allSanityColourPalette(
        sort: { order: ASC, fields: title }
      ) {
        edges {
          node {
            title
            backgroundColour {
              hex
            }
            patternColour {
              hex
            }
            textOutlineColour {
              hex
            }
          }
        }
      }
      patterns: allSanityPattern(sort: { fields: title, order: ASC }) {
        edges {
          node {
            title
            patternImage {
              asset {
                fluid {
                  src
                }
                url
              }
            }
          }
        }
      }
    }
  `)

  const [popUp, setpopUp] = useState(false)
  const [menu, setMenu] = useState(false)

  const [filter, setFilter] = useState('')
  const [pattern, setPattern] = useState('')

  const colourPallets = data.colourPallets.edges.map((obj) => obj.node)
  const colourPalletsRandom = Math.floor(Math.random() * colourPallets.length)
  const randomColor = colourPallets[colourPalletsRandom]

  const patterns = data.patterns.edges.map((obj) => obj.node)
  const patternsRandom = Math.floor(Math.random() * patterns.length)

  const setColours = () => {
    //set globabl document colours
    document.documentElement.style.setProperty(
      '--colour-bg',
      `${randomColor.backgroundColour.hex}`
    )
    document.documentElement.style.setProperty(
      '--colour-outline',
      `${randomColor.textOutlineColour.hex}`
    )

    //takes chosen pattern colour converts from hex to rgb and creates the css filter to change the pattern colour
    const rgb = hexToRgb(randomColor.patternColour.hex)
    const color = new Color(rgb[0], rgb[1], rgb[2])
    const solver = new Solver(color)
    const result = solver.solve()

    setPattern(patterns[patternsRandom])
    setFilter(result.filter)

    console.log(
      `%c ${randomColor.title}: ${randomColor.backgroundColour.hex}!`,
      `color: ${randomColor.backgroundColour.hex}`
    )
    console.log(
      `%c ${patterns[patternsRandom].title}: ${randomColor.patternColour.hex}!`,
      `color: ${randomColor.patternColour.hex}`
    )
  }

  //if popUp is true hide content
  useEffect(() => {
    if (popUp) {
      document.documentElement.style.setProperty('--colour-bg', `#000`)
    } else {
      setColours()
    }
  }, [popUp])

  return (
    <>
      <PopUp popUp={popUp} setpopUp={setpopUp} />
      <div
        id='page'
        className={`${page && page}`}
        style={{
          overflow: popUp ? 'hidden' : 'visible',
          height: popUp && '100vh',
          opacity: popUp ? '0' : '1',
          pointerEvents: popUp ? 'none' : 'all',
        }}>
        <div
          className='background-pattern'
          style={{
            backgroundImage: `url(${
              pattern && pattern.patternImage.asset.fluid.src
            })`,
            backgroundColor: 'transparent',
            WebkitFilter: filter ? filter : 'none',
            filter: filter ? filter : 'none',
            display: filter ? 'block' : 'none',
          }}></div>
        <SEO title={title} url={url} />
        <Nav menu={menu} setMenu={setMenu} />
        <main id='content' className='content'>
          <div
            className='content___overlay'
            style={{
              opacity: menu ? '0' : '1',
              pointerEvents: menu ? 'none' : 'all',
            }}>
            {children}
          </div>
        </main>
        <Footer menu={menu} />
      </div>
    </>
  )
}
