import React, { useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Image from "./Image";

import menuIcon from "../../static/menu.svg";

export default function Nav({ menu, setMenu }) {
  const {
    sanitySiteSettings: { siteLogo }
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        siteLogo {
          asset {
            id
            originalFilename
            url
          }
          altText
        }
      }
    }
  `);

  useEffect(() => {
    if (menu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [menu]);

  return (
    <header className="header">
      <div className="header___bg">
        <div className="header___bg-overlay">
          <a href="https://badfijigyals.com" target="_blank">
            <Image
              image={siteLogo}
              maxWidth={85}
              style={{ width: `85px`, height: `45px` }}
            />
          </a>
          <button
            id="menu-toggle"
            aria-expanded={menu}
            aria-controls="menu"
            onClick={() => setMenu(!menu)}
          >
            <span>Menu</span>
            <img
              src={menuIcon}
              alt="menu icon"
              width="100%"
              height="100%"
              aria-hidden
            />
          </button>
        </div>
      </div>
      <nav role="navigation">
        <div
          className="menu-overlay"
          style={{ visibility: menu && "visible", opacity: menu && "1" }}
        >
          <ul id="menu">
            <li>
              <Link
                to="/"
                className="font-menu menu___link"
                onClick={() => setMenu(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="font-menu menu___link"
                onClick={() => setMenu(false)}
              >
                About
              </Link>
              <ul>
                <li>
                  <Link
                    to="/who-we-are"
                    className="font-menu menu___link"
                    onClick={() => setMenu(false)}
                  >
                    Who We Are
                  </Link>
                </li>
                <li>
                  <Link
                    to="/support"
                    className="font-menu menu___link"
                    onClick={() => setMenu(false)}
                  >
                    Support
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="font-menu menu___link"
                    onClick={() => setMenu(false)}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/fiji-baat"
                className="font-menu menu___link"
                onClick={() => setMenu(false)}
              >
                Fiji Baat
              </Link>
            </li>
            <li>
              <a
                href="https://coolie-cut-cane.badfijigyals.com"
                className="font-menu menu___link"
                onClick={() => setMenu(false)}
              >
                Coolie Cut Cane
              </a>
            </li>
            <li>
              <Link
                to="/projects"
                className="font-menu menu___link"
                onClick={() => setMenu(false)}
              >
                Projects
              </Link>
            </li>
            {/* <li>
              <Link
                to="/social-media"
                className="font-menu menu___link"
                onClick={() => setMenu(false)}
              >
                Social Media
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
}
