import React, { useState, useEffect } from "react";
import Image from "./Image";
import BlockContent from "@sanity/block-content-to-react";

import sparkle from "../../static/Sparkle.svg";

const positions = [
  {
    top: "-2rem",
    left: "-2rem",
    right: "",
    bottom: "",
    transform: "rotate(40deg)"
  },
  {
    top: "-2rem",
    left: "",
    right: "-2rem",
    bottom: "",
    transform: "rotate(-20deg)"
  },
  {
    top: "",
    left: "-2rem",
    right: "",
    bottom: "-2rem",
    transform: "rotate(160deg)"
  },
  {
    top: "",
    left: "",
    right: "-2rem",
    bottom: "-2rem",
    transform: "rotate(10deg)"
  }
];

const sanityConfig = { projectId: "tj5lg93h", dataset: "production" };

export default function PortableText({ blocks }) {
  const [sparklePosition, setSparklePosition] = useState({});

  useEffect(() => {
    const randomPosition = Math.floor(Math.random() * positions.length);
    setSparklePosition(positions[randomPosition]);
  }, []);

  const serializers = {
    types: {
      block: props => {
        if (props.node.style === "h3") {
          return <h3 className="font-h3">{props.children}</h3>;
        }
        return <p className="font-p">{props.children}</p>;
      },
      portableImage: props => {
        // const fluidProps = getGatsbyImageData(
        //   props.node.asset._ref,
        //   { maxWidth: 800 },
        //   sanityConfig
        // );
        return (
          <Image
            image={props.node}
            className={`portable-text___image${
              props.node.fullWidth ? " full-width" : ""
            }`}
          />
        );
      },
      fileBlock: props => {
        const file = props.node.file.asset._ref.replace("file-", "");
        const url = file.replace("-", ".");
        return (
          <a
            className="font-p link"
            href={`https://cdn.sanity.io/files/tj5lg93h/production/${url}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.node.title}
          </a>
        );
      },
      iframe: props => (
        <div
          className="i-frame"
          dangerouslySetInnerHTML={{ __html: props.node.url }}
        />
      )
    },
    marks: {
      link: ({ children, mark }) => (
        <a
          href={mark.href}
          target="_blank"
          rel="noopener noreferrer"
          className="font-p link"
        >
          {children}
        </a>
      ),
      strong: ({ children }) => (
        <strong className="font-p-bold">{children}</strong>
      ),
      em: ({ children }) => <em className="font-p">{children}</em>
    }
  };

  if (blocks) {
    return (
      <div className="portable-text">
        <img
          src={sparkle}
          alt="sparkle"
          aria-hidden
          className="sparkle"
          style={sparklePosition}
        />
        <BlockContent blocks={blocks} serializers={serializers} />
      </div>
    );
  } else {
    return null;
  }
}
